// 根据房号获取房号信息
const getHouseInfoUrl = `/gateway/hc-space/decoration/getHouseInfo`;
// 根据houseId判断是否已认证通过
const checkAuthedByHouseIdUrl = `/gateway/hc-space/decoration/checkAuthedByHouseId`;
// 获取装修贴士信息列表
const getMiniTipsListUrl = `/gateway/hc-space/decoration/getMiniTipsList`;
// 获取装修贴士信息详情
const getTipsDetailsUrl = `/gateway/hc-space/decoration/getTipsDetails`;
// 根据userId获取已认证的房屋信息;
const getAuthedSpaceInfoUrl = `/gateway/hc-space/decoration/getAuthedSpaceInfo`;
export {
  getHouseInfoUrl,
  checkAuthedByHouseIdUrl,
  getMiniTipsListUrl,
  getTipsDetailsUrl,
  getAuthedSpaceInfoUrl,
};
